<template>
  <v-dialog :value="value" @input="$emit('input', $event)" scrollable max-width="720px">
    <v-card class="px-6 pt-2">
      <v-card-title ></v-card-title>
      <v-card-text class="mb-5">
        <v-img :src="section.thumbnailUrl" :aspect-ratio="16/9" class="mb-3 thumbnail" style="background-color: gray;"/>
        <div class="name text-h5 black--text"> {{ section.label }} </div>
        <v-responsive height="100px" class="description">
          <span v-html="section.description"></span>
        </v-responsive>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn text width="190" @click="$emit('input', false)" tile>
          閉じる
        </v-btn>
        <!-- <v-btn color="btPrimary" class="white--text" width="190" tile depressed>
          サンプルを見る
        </v-btn> -->
        <v-btn depressed tile v-if="addMode && !section.isAdded" color="btAccent" class="white--text" width="190" @click="onClickRegisterBtn">
          <v-icon class="pr-1" dense>mdi-plus-circle-outline</v-icon>
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    section: Object,
    addMode: Boolean,
  },
  methods: {
    onClickRegisterBtn() {
      this.$emit('click-register-btn', {
        section: this.section,
      })
    },
    onClickSampleBtn() {
      this.$emit('click-sample-btn', {
        section: this.section,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.name {
  border-bottom: 5px solid #d1d1d1;
  padding: 0 0 3px 0;
  margin: 0 0 10px 0;
}

.description {
  overflow-y: auto;
  @include scrollAreaY();
}

.thumbnail {
  border: solid 2px #b8b8b8;
}
</style>