<template>
  <div>
    <!-- フィルター -->
    <v-row class="justify-center py-lg-3">
      <v-btn
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.ALL"
        @click="setFilter(ENUM_FILTER.ALL)"
      >
        全て
      </v-btn>
      <v-btn
        v-if="bp.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.BP"
        @click="setFilter(ENUM_FILTER.BP)"
      >
        BPシート
      </v-btn>
      <v-btn
        v-if="kpiDesign.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.KPI_DESIGN"
        @click="setFilter(ENUM_FILTER.KPI_DESIGN)"
      >
        KPI設計
      </v-btn>
      <v-btn
        v-if="businessDetails.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.BUSINESS_DETAILS"
        @click="setFilter(ENUM_FILTER.BUSINESS_DETAILS)"
      >
        事業理解
      </v-btn>
      <v-btn
        v-if="marketEnvironmentAnalysis.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.MARKET_ENVIRONMENT_ANALYSIS"
        @click="setFilter(ENUM_FILTER.MARKET_ENVIRONMENT_ANALYSIS)"
      >
        市場環境分析
      </v-btn>
      <v-btn
        v-if="strategyAndMeasuresPlanning.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.STRATEGY_AND_MEASURES_PLANNING"
        @click="setFilter(ENUM_FILTER.STRATEGY_AND_MEASURES_PLANNING)"
      >
        戦略／施策立案
      </v-btn>
      <v-btn
        v-if="designRequirements.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.DESIGN_REQUIREMENTS"
        @click="setFilter(ENUM_FILTER.DESIGN_REQUIREMENTS)"
      >
        デザイン要件
      </v-btn>
      <v-btn
        v-if="planning.length"
        rounded
        depressed
        color="grey"
        class="white--text mx-1 my-1"
        :width="btnWidth"
        :outlined="filter == ENUM_FILTER.PLANNING"
        @click="setFilter(ENUM_FILTER.PLANNING)"
      >
        プランニング
      </v-btn>
    </v-row>

    <!-- パーツ一覧 -->
    <v-row v-if="isShowListBp" class="py-3">
      <section-list
        :value="bp"
        label="BPシート"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      ></section-list>
    </v-row>
    <v-row v-if="isShowListKpiDesign" class="py-3">
      <section-list
        :value="kpiDesign"
        label="KPI設計"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      ></section-list>
    </v-row>
    <v-row v-if="isShowListBusinessDetails" class="py-3">
      <section-list
        :value="businessDetails"
        label="事業理解"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      ></section-list>
    </v-row>
    <v-row v-if="isShowListMarketEnvironmentAnalysis" class="py-3">
      <section-list
        :value="marketEnvironmentAnalysis"
        label="市場環境分析"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      ></section-list>
    </v-row>
    <v-row v-if="isShowListStrategyAndMeasuresPlanning" class="py-3">
      <section-list
        :value="strategyAndMeasuresPlanning"
        label="戦略／施策立案"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      />
    </v-row>
    <v-row v-if="isShowListDesignRequirements" class="py-3">
      <section-list
        :value="designRequirements"
        label="デザイン要件"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      />
    </v-row>
    <v-row v-if="isShowListPlanning" class="py-3">
      <section-list
        :value="planning"
        label="プランニング"
        :addMode="addMode"
        @click-add-btn="onClickAddBtn"
        @click-help-btn="onClickHelpBtn"
      ></section-list>
    </v-row>

    <!-- 詳細ダイアログ -->
    <parts-description-dialog
      v-if="currentItem"
      v-model="dialog.detail"
      :section="currentItem"
      :addMode="addMode"
      @click-register-btn="dialog.register = true"
      @click-sample-btn="dialog.detail = false"
    />

    <!-- 登録確認ダイアログ -->
    <yes-no-dialog
      v-if="dialog.register"
      :message="currentItem.label + 'をプロジェクトに追加しますか？'"
      v-model="dialog.register"
      yesText="追加"
      noText="キャンセル"
      center
      @clickYes="registerParts"
      @clickNo="dialog.register = false"
    />

    <!-- 既に登録されている場合のダイアログ -->
    <yes-no-dialog
      message="既に登録されています。編集画面に移動しますか。"
      v-model="dialog.moveConfirm"
      yesText="移動する"
      noText="キャンセル"
      center
      @clickYes="moveToEditPage(currentItem)"
      @clickNo="dialog.moveConfirm = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SectionList from "./SectionList.vue";
import PartsDescriptionDialog from "../molecules/PartsDescriptionDialog.vue";
import YesNoDialog from "../atoms/YesNoDialog.vue";

export default {
  components: {
    SectionList,
    PartsDescriptionDialog,
    YesNoDialog,
  },
  props: {
    sections: Array,
    addMode: { default: false },
  },
  data() {
    return {
      selectedSectionId: "",
      filter: 99, // 1:bpシート 2:KPI設計 3:事業理解 4:市場環境分析 5:戦略/施策立案 6:デザイン要件 7:プランニング 99: 全て
      dialog: {
        detail: false,
        register: false,
        moveConfirm: false,
      },
      // 定数として使用
      ENUM_FILTER: {
        ALL: 99,
        BP: 0,
        KPI_DESIGN: 1,
        BUSINESS_DETAILS: 2,
        MARKET_ENVIRONMENT_ANALYSIS: 3,
        STRATEGY_AND_MEASURES_PLANNING: 4,
        DESIGN_REQUIREMENTS: 5,
        PLANNING: 6,
      },
      btnWidth: "120",
    };
  },
  computed: {
    ...mapGetters(["registeredSections", "userInfo"]),
    bp() {
      // BPシート
      let result = this.allBpParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedBpParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    kpiDesign() {
      // KPI設計
      let result = this.allKpiDesignParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedKpiDesignParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    businessDetails() {
      // 事業理解
      let result = this.allBusinessDetailsParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedBusinessDetailsParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    marketEnvironmentAnalysis() {
      // 市場環境分析
      let result = this.allMarketEnvironmentAnalysisParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedMarketEnvironmentAnalysisParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    strategyAndMeasuresPlanning() {
      // 戦略/施策立案
      let result = this.allStrategyAndMeasuresPlanningParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedStrategyAndMeasuresPlanningParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    designRequirements() {
      // デザイン要件
      let result = this.allDesignRequirementsParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedDesignRequirementsParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    planning() {
      // プランニング
      let result = this.allPlanningParts.concat();
      result.forEach((e) => {
        // section-list用に追加済フラグを追加
        if (e.multiple) {
          e.isAdded = false;
        } else {
          e.isAdded = this.addedPlanningParts.find(
            (item) => item.section.sectionId == e.sectionId
          )
            ? true
            : false;
        }
      });
      return result;
    },
    currentItem() {
      // 選択された戦略パーツ情報
      return (
        this.sections.filter(
          (item) => item.sectionId == this.selectedSectionId
        )[0] ?? {}
      );
    },
    addedBpParts() {
      // BPシートの追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) => item.section.category == this.ENUM_FILTER.BP
      );
    },
    addedKpiDesignParts() {
      // BPシートの追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) => item.section.category == this.ENUM_FILTER.KPI_DESIGN
      );
    },
    addedBusinessDetailsParts() {
      // 事業理解の追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) => item.section.category == this.ENUM_FILTER.BUSINESS_DETAILS
      );
    },
    addedMarketEnvironmentAnalysisParts() {
      // 市場環境分析の追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) =>
          item.section.category == this.ENUM_FILTER.MARKET_ENVIRONMENT_ANALYSIS
      );
    },
    addedStrategyAndMeasuresPlanningParts() {
      // 戦略/施策立案の追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) =>
          item.section.category ==
          this.ENUM_FILTER.STRATEGY_AND_MEASURES_PLANNING
      );
    },
    addedDesignRequirementsParts() {
      // デザイン要件の追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) => item.section.category == this.ENUM_FILTER.DESIGN_REQUIREMENTS
      );
    },
    addedPlanningParts() {
      // プランニングの追加済パーツ
      return this.registeredSections?.sections?.filter(
        (item) => item.section.category == this.ENUM_FILTER.PLANNING
      );
    },
    allBpParts() {
      // BPシートの全パーツ
      return this.sections.filter(
        (item) => item.category == this.ENUM_FILTER.BP
      );
    },
    allKpiDesignParts() {
      // KPI設計の全パーツ
      return this.sections.filter(
        (item) => item.category == this.ENUM_FILTER.KPI_DESIGN
      );
    },
    allBusinessDetailsParts() {
      // 事業理解の全パーツ
      return this.sections.filter(
        (item) => item.category == this.ENUM_FILTER.BUSINESS_DETAILS
      );
    },
    allMarketEnvironmentAnalysisParts() {
      // 市場環境分析の全パーツ
      return this.sections.filter(
        (item) => item.category == this.ENUM_FILTER.MARKET_ENVIRONMENT_ANALYSIS
      );
    },
    allStrategyAndMeasuresPlanningParts() {
      // 戦略/施策立案の全パーツ
      return this.sections.filter(
        (item) =>
          item.category == this.ENUM_FILTER.STRATEGY_AND_MEASURES_PLANNING
      );
    },
    allDesignRequirementsParts() {
      // デザイン要件の全パーツ
      return this.sections.filter(
        (item) => item.category == this.ENUM_FILTER.DESIGN_REQUIREMENTS
      );
    },
    allPlanningParts() {
      // デザイン要件の全パーツ
      return this.sections.filter(
        (item) => item.category == this.ENUM_FILTER.PLANNING
      );
    },
    isShowListBp() {
      // パーツ一覧にBPシートを表示するかどうか
      return (
        this.bp.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.BP)
      );
    },
    isShowListKpiDesign() {
      // パーツ一覧にKPI設計を表示するかどうか
      return (
        this.kpiDesign.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.KPI_DESIGN)
      );
    },
    isShowListBusinessDetails() {
      // パーツ一覧に事業理解を表示するかどうか
      return (
        this.businessDetails.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.BUSINESS_DETAILS)
      );
    },
    isShowListMarketEnvironmentAnalysis() {
      // パーツ一覧に市場環境分析を表示するかどうか
      return (
        this.marketEnvironmentAnalysis.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.MARKET_ENVIRONMENT_ANALYSIS)
      );
    },
    isShowListStrategyAndMeasuresPlanning() {
      // パーツ一覧に戦略/施策立案を表示するかどうか
      return (
        this.strategyAndMeasuresPlanning.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.STRATEGY_AND_MEASURES_PLANNING)
      );
    },
    isShowListDesignRequirements() {
      // パーツ一覧にデザイン要件を表示するかどうか
      return (
        this.designRequirements.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.DESIGN_REQUIREMENTS)
      );
    },
    isShowListPlanning() {
      // パーツ一覧にプランニングを表示するかどうか
      return (
        this.planning.length &&
        (this.filter == this.ENUM_FILTER.ALL ||
          this.filter == this.ENUM_FILTER.PLANNING)
      );
    },
  },
  async created() {
    // 既に登録されているセクションを取得
    await this.getRegisteredSections();
  },
  methods: {
    ...mapActions(["getRegisteredSections", "createSelectedSection"]),
    setFilter(val) {
      this.filter = val;
    },
    onClickAddBtn(e) {
      // 「追加」が選択されたセクション情報を取得
      this.selectedSectionId = e.sectionId;

      // 登録確認ダイアログ起動
      if (Object.keys(this.currentItem).length) {
        this.dialog.register = true;
      }
    },
    onClickHelpBtn(e) {
      // 「？」が選択されたセクション情報を取得
      this.selectedSectionId = e.sectionId;

      // パーツ解説ダイアログ起動
      if (Object.keys(this.currentItem).length) {
        this.dialog.detail = true;
      }
    },
    async registerParts() {
      // セクションを登録して編集画面に移動する。
      await this.createSelectedSection({
        sectionId: this.currentItem.sectionId,
        userId: this.userInfo.userId,
      });
      this.moveToEditPage(this.currentItem);
    },
    moveToEditPage(section) {
      this.$router.push({
        name: "StrategyPartsEditPage",
        params: { partsName: section.dataRef },
      });
    },
  },
};
</script>
