<template>
  <v-dialog
    v-model="dialog"
    transition="scale-transition"
    persistent
    max-width="1400"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card tile>
      <v-toolbar color="btPrimary" dense class="toolbar" elevation="0">
        <v-btn icon @click="close()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
        <div class="ml-8 white--text">戦略のパーツ追加</div>
      </v-toolbar>
      <div class="strategy-edit-main-container mt-3 mb-10 px-10" v-if="isInit">
        <v-container>
          <v-row class="mt-1 mb-2">
            <v-col cols="12" class="title text-center">
              <div class="text-body-1 pt-2" align="center">
                各パーツの「追加」ボタンをクリックして、プロジェクトに追加します。
              </div>
            </v-col>
          </v-row>
          <filterable-parts-group :addMode="true" :sections="sections.sections" />
        </v-container>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FilterablePartsGroup from "./FilterablePartsGroup";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "StrategyPartsAddDialog",
  components: {
    FilterablePartsGroup,
  },
  props: ["projectId", "value"],
  data() {
    return {
      brandSections: {},
      frameworkSections: {},
      otherSections: {},
      currentSectionItem: null,
      isInit: false,
    };
  },
  computed: {
    ...mapGetters(["project", "sections"]),
    dialog() {
      return this.value;
    }
  },
  created() {
    // 読み取りモード、アーカイブの場合はダイアログを閉じる
    if(this.$route.query?.mode == 'readonly' || !!this.project.archivedAt) this.close();

    //初期化
    this.init();
  },
  methods: {
    ...mapActions(["getAllSection"]),
    async init() {
      // セクションの一覧を取得
      const params = {
        industry: this.project.industry,
      }
      await this.getAllSection(params);
      this.isInit = true;
    },
    close() {
      this.$router.push({name: 'StrategyTopPage', query: this.getQueryParamProjectReadonly() });
    }
  },
};
</script>
<style lang="scss" scoped>
.strategy-edit-main-container {
  margin: 0 auto;
  max-width: 1180px;
}
.toolbar {
  position: sticky;
  top: 0px;
  z-index: 5;
}
</style>
