<template>
  <v-container fluid>
    <v-row v-if="label">
      <v-col cols="12" class="label text-h5">
        <span>{{ label }}</span>
      </v-col>
    </v-row>
    <v-row class="py-2">
      <v-col
        lg="3"
        md="4"
        sm="6"
        cols="12"
        v-for="item in value"
        :key="item.sectionId"
        class="py-2 px-2"
      >
        <section-list-item
          :sectionId="item.sectionId"
          :link="
            addMode
              ? ''
              : {
                  name: 'StrategyPartsEditPage',
                  params: {
                    partsName: item.dataRef,
                    paramSectionId: item.sectionId,
                  },
                  query: getQueryParamProjectReadonly(),
                }
          "
          :label="item.label"
          :thumbnailUrl_="item.thumbnailUrl"
          :description="item.description"
          :topic="item.topic"
          :addMode="addMode"
          :isAdded="item.isAdded"
          @click-add-btn="onClickAddBtn"
          @click-help-btn="onClickHelpBtn"
        ></section-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectionListItem from "../molecules/SectionListItem.vue";
export default {
  components: { SectionListItem },
  props: {
    value: Array,
    addMode: Boolean,
    label: String,
  },
  methods: {
    onClickAddBtn(e) {
      this.$emit("click-add-btn", {
        sectionId: e.sectionId,
      });
    },
    onClickHelpBtn(e) {
      this.$emit("click-help-btn", {
        sectionId: e.sectionId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  border-left: 10px solid #d1d1d1;
  border-bottom: 5px solid #d1d1d1;
  padding: 0 0 3px 10px;
}
</style>
