<template>
  <v-card
    outlined
    color="#FAFAFA"
    class="parts-item-card ma-0 px-3 py-3 rounded-lg"
    width="100%"
  >
    <div class="d-flex flex-column">
      <v-img :src="thumbnailUrl" :aspect-ratio="16/9" class="thumbnail"></v-img>
      <v-card-title class="px-1 pt-1 pb-0">
        <div class="d-inline-flex align-top font-weight-bold text-subtitle-1">
          <div class="label text-wrap pt-1">{{ label }}</div>
          <v-btn icon right @click="onClickHelpBtn" class="pb-1">
            <v-icon color="btPrimary" class="px-1"
              >mdi-help-circle-outline</v-icon
            >
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="addMode" class="px-1 py-0">
        <div class="description text-wrap">
          <span v-html="description"></span>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-0">
        <div v-if="addMode">
          <span v-if="isAdded" class="text-button">
            <v-icon color="btCancel"
              >mdi-check-circle</v-icon
            >
            追加済
          </span>
          <v-btn
            v-else
            tile
            depressed
            color="btPrimary"
            class="white--text px-3"
            @click="onClickAddBtn"
          >
            <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
            追加
          </v-btn>
        </div>
        <div v-else>
          <ex-v-btn
            tile
            depressed
            color="btPrimary"
            class="white--text px-3"
            :to="link"
            :label="hasWritePermission ? '編集' : '閲覧'"
            :icon="hasWritePermission ? 'mdi-pencil-outline' : 'mdi-eye-outline'"
          />
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import YesNoDialog from "../atoms/YesNoDialog.vue";
import ExVBtn from "../atoms/ExVBtn.vue";
import { mapGetters } from "vuex"

export default {
  components: {
    YesNoDialog,
    ExVBtn,
  },
  props: {
    sectionId: {},
    link: {},
    label: {},
    topic: {},
    description: {},
    thumbnailUrl_: {},
    addMode: {
      default: false,
    },
    isAdded: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["hasWritePermission"]),

    thumbnailUrl() {
      return !!this.thumbnailUrl_
        ? this.thumbnailUrl_
        : "/img/parts_thumbnail.jpg";
    },
  },
  methods: {
    onClickAddBtn() {
      this.$emit("click-add-btn", {
        sectionId: this.sectionId,
      });
    },
    onClickHelpBtn() {
      this.$emit("click-help-btn", {
        sectionId: this.sectionId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.parts-item-card {
  border: 4px solid #dee0e1 !important;
  border-radius: 20px !important;
  width: fit-content;
  height: fit-content;
}
.label {
  display: -webkit-box;
  line-height: 1.3em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  height: 47px;
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 70px;
}
.thumbnail {
  border: solid 2px #b8b8b8;
}
</style>
