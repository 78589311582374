var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.label)?_c('v-row',[_c('v-col',{staticClass:"label text-h5",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.label))])])],1):_vm._e(),_c('v-row',{staticClass:"py-2"},_vm._l((_vm.value),function(item){return _c('v-col',{key:item.sectionId,staticClass:"py-2 px-2",attrs:{"lg":"3","md":"4","sm":"6","cols":"12"}},[_c('section-list-item',{attrs:{"sectionId":item.sectionId,"link":_vm.addMode
            ? ''
            : {
                name: 'StrategyPartsEditPage',
                params: {
                  partsName: item.dataRef,
                  paramSectionId: item.sectionId,
                },
                query: _vm.getQueryParamProjectReadonly(),
              },"label":item.label,"thumbnailUrl_":item.thumbnailUrl,"description":item.description,"topic":item.topic,"addMode":_vm.addMode,"isAdded":item.isAdded},on:{"click-add-btn":_vm.onClickAddBtn,"click-help-btn":_vm.onClickHelpBtn}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }